import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery'
import { HostListener } from '@angular/core';
import { from } from 'rxjs';
import { Router, NavigationStart } from '@angular/router';

@Component({
  selector: 'app-admin',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})

export class LayoutComponent implements OnInit {

  isShow: boolean;
  topPosToStartShowing = 100;
  showHead: boolean = false;
  collapseMenu = false;

  public toggleNavbar() {
    let innerWidth = window.innerWidth;

    console.log('toggleNavbar calling for screen width : ' + innerWidth)
    if(innerWidth < 1091){

    
    
    }
    

  }
  

  ngOnInit() {
  }

  constructor(private router: Router) {
    // on route change to '/login', set the variable showHead to false
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        if (event['url'] == '/home') {
          this.showHead = false;
        } else {
          (event['url'] == '/')
          this.showHead = true;
        }
      }
    });
  }



  @HostListener('window:scroll')
  checkScroll() {

    // window의 scroll top
    // Both window.pageYOffset and document.documentElement.scrollTop returns the same result in all the cases. window.pageYOffset is not supported below IE 9.

    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    console.log('[scroll]', scrollPosition);

    if (scrollPosition >= this.topPosToStartShowing) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
  }

  // TODO: Cross browsing
  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  ngAfterViewInit() {
    $.getScript("./assets/js/main.js", function () {
    });
  }


}
