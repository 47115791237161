<!-- ======= Header ======= -->
<header id="header" class="fixed-top d-flex align-items-center header">
  <div class="container-fluid">

    <div class="row justify-content-center align-items-center">
      <div class="col-xl-11 d-flex align-items-center justify-content-between">
        <h1 class="logo"><a [routerLink]="['/home']"><img src="./assets/Amstechlogowhite.png"></a></h1>
        <!-- Uncomment below if you prefer to use an image logo -->
        <!-- <a href="index.html" class="logo"><img src="assets/img/logo.png" alt="" class="img-fluid"></a>-->

        <nav id="navbar" class="navbar">
          <ul>
            <li><a class="nav-link scrollto" [routerLink]="['/portfolio']">portfolio</a></li>
            <li class="dropdown"><a><span>About</span> <i class="bi bi-chevron-down"></i></a>
              <ul>
                <li><a [routerLink]="['/about-us']">
                    Our Company</a></li>
                <!-- <li><a [routerLink]="['/about-us/our-approach/']">Our Approach</a></li> -->
                <li><a [routerLink]="['/about-us/our-client/']">Our Clients</a></li>
                <li><a [routerLink]="['/about-us/testimonial/']">Testimonials</a></li>
                <li><a [routerLink]="['/about-us/cookies-policy/']">Cookies Policy</a></li>
                <li><a [routerLink]="['/about-us/privacy-policy/']">Privacy Policy</a></li>
                <li><a [routerLink]="['/about-us/refund-policy/']">Refund Policy</a></li>
              </ul>
            </li>
            <li class="dropdown"><a><span>Services</span> <i class="bi bi-chevron-down"></i></a>
              <ul>
                <li><a [routerLink]="['/our-service/mobile-web']">Mobile & Web Development</a></li>
                <li><a [routerLink]="['/our-service/business-it']">Business IT Solution</a></li>
                <li><a [routerLink]="['/our-service/product-engineering']">Product Engineering</a>
                </li>
                <li><a [routerLink]="['/our-service/data-scienceandml']">Data science
                  </a></li>
                <li><a [routerLink]="['/our-service/research-development']">Augmented &
                    Virtual Reality</a></li>
                <li><a [routerLink]="['/our-service/IOT-infrastructure']">Internet of Things</a>
                </li>
                <li><a [routerLink]="['/our-service/cloud-storage']">Cloud Storage</a></li>
                <li><a [routerLink]="['/our-service/wireframing-prototyping']"> UI/UX Design
                  </a> </li>
                <li><a [routerLink]="['/our-service/corporate-schooling']">Corporate Schooling</a>
                </li>
              </ul>
            </li>
            <li class="dropdown"><a><span >Products</span> <i class="bi bi-chevron-down"></i></a>
              <ul>
                <li><a [routerLink]="['/products/campus-manage']">Smart Coaching</a></li>
                <li><a [routerLink]="['/products/online-exam']">Online Examination</a></li>
                <li><a [routerLink]="['/products/inquiry-manage']">Inquiry & Fee Management</a></li>
                <li><a [routerLink]="['/products/human-resource']">Human Resource Management</a>
                </li>
              </ul>
            </li>
            <li><a class="nav-link scrollto" [routerLink]="['/technologies']">Technologies</a></li>
            <li class="dropdown"><a><span >Industry</span> <i class="bi bi-chevron-down"></i></a>
              <ul>
                <li><a [routerLink]="['/industry/education']">Education</a></li>
                <li><a [routerLink]="['/industry/horeca']">HoReCa</a></li>
                <li><a [routerLink]="['/industry/e-commerce']">Ecommerce</a></li>
                <li><a [routerLink]="['/industry/enterprize-solution']">Enterprize Solution</a></li>
                <li><a [routerLink]="['/industry/retail-management']">Retail Management</a></li>
              </ul>
            </li>
            <li><a class="nav-link scrollto" [routerLink]="['/contact-us']">Contact</a></li>
          </ul>
          <i class="bi bi-list mobile-nav-toggle"></i>
        </nav><!-- .navbar -->
      </div>
    </div>

  </div>
</header><!-- End Header -->


<router-outlet>

</router-outlet>

<!-- ======= Footer ======= -->
<footer id="footer">
  <div class="footer-top">
    <div class="container">
      <div class="row">

        <div class="col-lg-4 col-md-6 footer-info">
          <h3><a [routerLink]="['/home']"><img src="./assets/Amstechlogowhite.png" width="70%" height="100%"></a></h3>
          <p>Amstech Incorporation Private Limited is a leading Software Services and IT consulting, Web Development
            Company, specializing in providing complete end-to-end E- Commerce solutions.</p>
        </div>

        <div class="col-lg-3 col-md-6 footer-links">
          <h4>Useful Links</h4>
          <ul>
            <li><i class="bi bi-chevron-right"></i> <a [routerLink]="['/home']">Home</a></li>
            <li><i class="bi bi-chevron-right"></i> <a [routerLink]="['/about-us']">About Us</a></li>
            <li><i class="bi bi-chevron-right"></i> <a [routerLink]="['/our-service/mobile-web']">Services</a></li>
            <li><i class="bi bi-chevron-right"></i> <a [routerLink]="['/products/campus-manage']">Products</a>
            </li>
            <li><i class="bi bi-chevron-right"></i> <a [routerLink]="['/technologies']">Technologies</a>
            </li>
            <li><i class="bi bi-chevron-right"></i> <a [routerLink]="['/industry/education']">Industry</a>
            </li>
            <li><i class="bi bi-chevron-right"></i> <a [routerLink]="['/contact-us']">Contact Us</a>
            </li>
          </ul>
        </div>

        <div class="col-lg-4 col-md-6 footer-contact">
          <h4>Contact Us</h4>
          <p>
            Veda Business Park
            Bhawarkua Main Square, Indore,
            Madhya Pradesh, India PinCode: 452009 <br>
            <strong>Phone:</strong> +91-88899-95359<br>
            <strong>Email:</strong> info@amstechinc.com<br>
          </p>

          <div class="social-links">
            <a href="https://twitter.com/Amstechinc?lang=en" target="blank" class="twitter"><i
                class="bi bi-twitter"></i></a>
            <a href="https://www.facebook.com/Amstechinc/" target="blank" class="facebook"><i
                class="bi bi-facebook"></i></a>
            <a href="https://www.instagram.com/Amstechincorpora/" target="blank" class="instagram"><i
                class="bi bi-instagram"></i></a>
            <a href="https://github.com/anshulsharma31" target="blank" class="instagram"><i
                class="bi bi-github"></i></a>
            <a href="https://www.linkedin.com/company/Amstechinc" target="blank" class="linkedin"><i
                class="bi bi-linkedin"></i></a>
          </div>

        </div>

       

      </div>
    </div>
  </div>

  <div class="container">
    <div class="copyright">
      &copy; Copyright <strong>2021</strong>. All Rights Reserved
    </div>
    <div class="credits">
      <!--
          All the links in the footer should remain intact.
          You can delete the links only if you purchased the pro version.
          Licensing information: https://bootstrapmade.com/license/
          Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/buy/?theme=BizPage
        -->
      Designed & Maintained by <a href="https://www.amstechinc.com.com/" target="blank">Amstech Incorporation Pvt. Ltd.</a>
    </div>
  </div>
</footer><!-- End Footer -->

<a (click)="gotoTop()" class="back-to-top d-flex align-items-center justify-content-center"><i
    class="bi bi-arrow-up-short"></i></a>
<!-- Uncomment below i you want to use a preloader -->
<!-- <div id="preloader"></div> -->