import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { LayoutComponent } from './layout/layout.component';

const routes: Routes = [
  {
    path: '',
    component: AppComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./web-pages/home/home.module').then(m => m.HomeModule),
        pathMatch: 'full'
      },

      {
        path: 'home',
        loadChildren: () => import('./web-pages/home/home.module').then(m => m.HomeModule)
      },
    ]
  },

  {
    path: '',
    component: LayoutComponent,
    children: [
      

      {
        path: 'portfolio',
        loadChildren: () => import('./web-pages/portfolio/portfolio.module').then(m => m.PortfolioModule)
      },
      {
        path: 'technologies',
        loadChildren: () => import('./web-pages/technologies/technologies.module').then(m => m.TechnologiesModule)
      },
      {
        path: 'contact-us',
        loadChildren: () => import('./web-pages/contact-us/contact-us.module').then(m => m.ContactUsModule)
      },
      {
        path: 'about-us',
        loadChildren: () => import('./web-pages/about-us/about-us.module').then(m => m.AboutUsModule)
      },

      {
        path: 'industry',
        loadChildren: () => import('./web-pages/industry/industry.module').then(m => m.IndustryModule)
      },

      {
        path: 'our-service',
        loadChildren: () => import('./web-pages/our-service/our-service.module').then(m => m.OurServiceModule)
      },

      {
        path: 'products',
        loadChildren: () => import('./web-pages/products/products.module').then(m => m.ProductsModule)
      },


    ]
  }

];



@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
